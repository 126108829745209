import React from 'react'
import Whatsapp from './Whatsapp'

function WsWidget(){
    return(
        <div className="d-flex align-items-end flex-column mb-5 pb-md-3 pb-3 pr-md-3 pr-3 fixed-bottom">
            <a href="https://wa.me/56975574509" target="blank">
                <Whatsapp width={36} height={36}/>
            </a>
        </div>
    )
}
export default WsWidget