import React from 'react'
import { Container, Row, Col } from 'reactstrap'
function Cotizar() {
  return (
    <div id="cotizar" style={{paddingTop:'60px',paddingBottom:'60px'}}>
        <Container>
            <Row>
              <Col className="ml-auto mr-auto left pb-5" md="8">
                <h2 className="title text-center">Cotizador</h2>
                <h3 className="description text-center">
                  Obtén tu cotización hoy mismo
                </h3>
                
              </Col>
              <Col sm="12">
                <mobysuite-quote real-estate="borderio" project-id="4" template="Lotes"></mobysuite-quote>
              </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Cotizar